<template>
  <div>
      <div class="form-div">
        
        <table border="0" >
            <tr>
                <th><span class="validate"></span>现住地址</th>
                <th>与住址关系</th>
                <th>与户主关系</th>
                <th>是否家庭户代表</th>
                <th v-if="$route.query.isEdit == 'true'">审核状态</th>
                <th v-if="$route.query.isEdit == 'true'">家庭成员</th>
                <th style="font-size:30px"><Button class="addDel" type="primary" @click="openAddFamily">+</Button></th>
            </tr>

            <tr v-for="(item,index) in formDataHome" :key="item">
                <td >
                  <Input placeholder="请输入您的房号信息" :maxlength="20"  enter-button="选择" v-model="item.addr" search @on-search="searchRoom(index)" @on-change="changeHouseAddr(index)" />
                </td>
                <!--  -->
                <td>
                  <Select  style="width:200px" v-model="item.userType" clearable >
                    <Option
                    v-for="item in registeredType"
                    :value="item.dictKey"
                    :key="item.dictKey"
                    >{{ item.dictValue }}</Option>
                  </Select>
                </td>
                <td>
                  <Select  style="width:200px" v-model="item.relationship" clearable >
                    <Option
                    v-for="item in relationshipList"
                    :value="item.dictKey"
                    :key="item.dictKey"
                    >{{ item.dictValue }}</Option>
                  </Select>
                </td>
                <!--  -->
                <td class="td-cneter">
                    <i-switch v-model="item.contact"  @on-change="val => contactChange(index,val)" true-value='1' false-value="2" />
                </td>
                <!--  -->
                <td v-if="$route.query.isEdit == 'true' && item.familyId" class="td-cneter">{{item.status==1?'待审核':item.status==2?'已通过':item.status==3?'未通过':""}}</td>
                <!-- 审核状态；1：待审核；2：已审核通过；3：审核未通过 -->
                <td v-if="$route.query.isEdit == 'true' && item.familyId"><Button  type="primary" @click="getfamilyMember(item.familyId)" >查看成员</Button></td>
                <td style="font-size:30px"><Button class="addDel" type="error" @click="delFamily(index)">-</Button></td>
            </tr>

            
        </table>
        <LiefengModal
          title="选择房号信息"
          :value="addrStatus"
          @input="addrStatusFn"
          :fullscreen="false"
          width="30%"
          class="edit"
        
        >
          <template v-slot:contentarea>
              <Tree v-if="showTree" :code="treeCode" :name="treeName" @handleTree="handleTree"></Tree>
          </template>
          <template v-slot:toolsbar>
          <Button style="margin: 0 5px 0 13px" type="warning" @click="addrTip=true;addrStatusFn(false);">取消</Button>
          <Button style="margin: 0 5px 0 13px" type="primary" @click="addrSave()">保存</Button>
        </template>
      </LiefengModal>
      <LiefengModal
        :value="familyMemberStatus"
        @input="familyMemberStatusFn"
        title="家庭成员信息"
        width="932px"
      >
        <template v-slot:contentarea>
              <Table 
                border
                :loading="loading"
                :columns="columns1" 
                :data="familyMember"
              ></Table>
        </template>
        
      </LiefengModal>
      <LiefengModal
        :value="addFamilyStatus"
        @input="addFamilyStatusFn"
        title="添加住址"
        width="932px"
      >
        <template v-slot:contentarea>
              
        </template>
        <template v-slot:toolsbar>
          <Button style="margin: 0 5px 0 13px" type="warning" @click="addFamilyStatusFn(false);">取消</Button>
           
          <Button style="margin: 0 5px 0 13px" type="primary" @click="addFamily()">保存</Button>
        </template>
        
      </LiefengModal>
        
      </div>
  </div>
</template>

<script>
import Tree from '../childrens/tree'
import LiefengModal from "@/components/LiefengModal";
export default {
  props:{
    formDataHome:{
      type:Object,
      default:()=>{
        return {}
      }
    },
    orgCode:{
            type:Object,
            default:()=>{
                return ''
            }
        },
    userId:{
            type:Object,
            default:()=>{
                return ''
            }
        },
        familyMemberId:{
            type:Object,
            default:()=>{
                return ''
            }
        }
  },
  data(){
    return{
      // familyList:[],
      registeredType:[], //与地址关系
      relationshipList:[], //与户主关系

      addrStatus:false, 
      showTree:false,
      treeCode:'',
      treeName:'',
      addrTip:false,
      selectTree: [],
      index:0,

      addFamilyStatus:false,



      familyMemberStatus:false,
      familyMember:[],
      loading:false,
      columns1:[
        {
          title:'与户主关系',
          width: 280,
          align: "center",
           render: (h, params) => {
            return h('div',{},
            params.row.relationship?(this.relationshipList.map(item => {
              if(item.dictKey == params.row.relationship){
                return item.dictValue
              }
            })):'本人'
            )
          },
        },
        {
          title:'姓名',
          align: "center",
          width: 120,
          key:'name',
        },
        {
          title:'性别',
          align: "center",
          width: 80,
           render: (h, params) => {
            return h("div", {},params.row.sex == '0' ?'未知':params.row.sex == '1' ?'男':params.row.sex == '2'?'女':'')
          },
        },
        {
          title:'年龄',
          width: 80,
          align: "center",
          key:'age',
        },
        {
          title:'是否家庭代表户',
          width: 200,
          align: "center",
          render: (h, params) => {
            return h("div", {},params.row.contact  == 1 ?'是':'否')
          },
        },
        {
          title:'手机号',
          width: 150,
          align: "center",
          key:'mobile',
        }
      ]
    }
  },
  created(){
    // console.log(this.orgCode)
      this.getDicType('ONLINE_HOME_USER_TYPE','registeredType')
      this.getDicType('FAMILY_RELATIONSHIP','relationshipList')
      // this.getDetail()
      // console.log(this.orgCode)
  },
  methods:{
    
    // 获取字典接口的公共方法
        getDicType(type,model){
            this.$get('/voteapi/api/common/sys/findDictByDictType',{
                dictType:type
            }).then(res=>{
                this[model] = res.dataList
            })
        },
        // 获取家庭成员
        getFamilyMemberList(id){
          this.$get('/syaa/api/syuser/app/residentArchive/selectUserFamilyMemberList',{
            familyId:id
          }).then(res => {
            if(res.code == 200){
              this.loading = false
              this.familyMember = res.dataList
              // console.log(res,'家庭成员');
            }
          })
        },
        // 树结构保存按钮
        addrSave(index){
            if(this.selectTree && this.selectTree.length ==0){
                this.$Message.warning({
                content:'请选择房号信息',
                background:true
              })
              return
          }else{
            if(this.selectTree[0].regionType != '0' && this.selectTree[0].regionType != '4'){
                this.$Message.warning({
                content:'现住地址请选择到房号',
                background:true
                })
                return
             }else{  
               this.formDataHome[this.index].addr = this.address
              this.formDataHome[this.index].houseCode = this.selectTree[0].code
              this.addrStatus = false
              this.showTree = false
              this.addrTip =false
              
            }
          }
           
        },

        contactChange(index,e){
          this.formDataHome[index].contact = e
        },

        searchRoom(index){   
          this.index = index 
            if(!this.orgCode || this.orgCode == '' || this.orgCode.length != 3){
                this.$Message.warning({
                    content:'请先选择所属社区',
                    background:true
                })
                return
            }
            this.treeCode = ''
            this.treeCode = this.orgCode[this.orgCode.length - 1]
            this.addrStatus = true
            this.$nextTick(()=>{
                this.showTree = true
            })   
             
        },

         // 点击了树节点的东西
        handleTree(val,treeData){
          this.selectTree = val 
          this.treeData = []
          this.treeData = treeData;
          this.address = '';
          this.getCurrentTitle(this.selectTree[0].id,treeData);
        },
        // 如果房号input框改变了
        changeHouseAddr(index){
            this.formDataHome[index].houseCode = ''
           
        },
            //递归面包屑
        getCurrentTitle(id, treeData) {
            for(let k in treeData) {
                if(treeData[k].id == id) {
                this.address = (treeData[k].name || treeData[k].orgName) + this.address;
                if(!treeData[k].parentId) return;
                this.getCurrentTitle(treeData[k].parentId,this.treeData)
                }else if(treeData[k].children && treeData[k].children.length > 0){
                this.getCurrentTitle(id,treeData[k].children)
                }
            }
        },
         // 保存按钮
        addrStatusFn(status){
          if(!status && this.addrTip){
            this.addrStatus = status
            this.showTree = false
            this.addrTip =false
            this.treeCode = ''
          }else{
            this.addrStatus = status
            this.showTree = false
            this.addrTip =false
          }
        },

        getfamilyMember(id){
          this.familyMember = []
          this.familyMemberStatus = true
          this.loading = true
          this.getFamilyMemberList(id)
        },

        familyMemberStatusFn(status){
          this.familyMemberStatus = status
        },

        addFamilyStatusFn(status){
          this.addFamilyStatus = status
        },
        openAddFamily(){
          for(let i=0;i<this.formDataHome.length;i++){
            if(!this.formDataHome[i].houseCode){
              this.$Message.warning({
                content:'请先完善现住地址信息',
                background:true
              })
              return 
            }
          }
          this.formDataHome.push({
            addr:'',
            contact:'2',
            houseCode:'',
            userType:'',
            status:'2',
            relationship:''
          })
        },

        addFamily(){
          this.addFamilyStatusFn(false)
        },

        delFamily(index){
          // console.log(this.formDataHome)
          // console.log(index,'------------------索引')
          if(this.formDataHome.length == 1){
            this.$Message.warning({
              content:'最后一条无法删除',
              background:true
            })
          }else{
            this.$Modal.confirm({
              title: "温馨提示",
              content: "是否删除此条数据",
              onOk: () => {
                this.formDataHome.splice(index,1)
              }
            });
          }
        },
        
  },
  components:{Tree,LiefengModal},
}
</script>

<style lang="less" scoped>
.form-div{
    width:100%;
    .isForm{
        display: flex;
        flex-wrap: wrap;
        .isWidth{
        width: 40%;
        margin-right: 40px;
        }
        .isNoWidth{
            width: 30%;
        }
    }
    table {
      tr{
        th{
          font-weight: normal;
          text-align: left !important;
          padding: 9px 20px;
        }
        td{
          
          padding: 13px 20px;
          // text-align: center;
        }
        .td-cneter{
          text-align: center;
        }
      }
    }
    
}
/deep/.validate {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}
/deep/.ivu-date-picker{
    display: inline;
}
/deep/.addDel{
  width: 50px;
  height: 50px;
  font-size: 30px;
  line-height: 30px;
  

}
</style>